import {
  Backdrop,
  Box,
  Button,
  Container,
  Fade,
  Link,
  Modal,
  Tab,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { css, cx } from "@emotion/css";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import api from "../../service/api";
import TabPanel from "../../components/TabPanel";

import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import { format } from "date-fns";

function ProfilePage() {
  const [profile, setProfile] = useState({
    nama: "",
    npsn: "",
    email: "",
    alamat: "",
    status: "",
    jenjang: "",
    desaKelurahan: "",
    kecamatan: "",
    kabupatenKota: "",
    provinsi: "",
    postcode: "",
    phoneNumber: "",
    yearEst: "",
    principalsName: "",
    adminsName: "",
    adminsWhatsapp: "",
  });
  const [error, setError] = useState();

  const { schoolid } = useParams();

  const sendProfile = async () => {
    var returnStatus, returnResponse;
    await api
      .put(`admin/schools/${schoolid}`, profile, {
        headers: {
          "x-access-token": `${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      })
      .then((res) => {
        console.log(res.data);

        if (res.status == 200) {
          // Success
        }

        returnStatus = "success";
        returnResponse = "Berhasil";
      })
      .catch((err) => {
        console.log(err);
        returnStatus = "error";
        returnResponse = "Gagal";
        setError(err.toString());
      });

    return {
      status: returnStatus,
      text: returnResponse,
    };
  };

  const getProfile = async () => {
    var returnStatus, returnResponse;
    await api
      .get(`admin/schools/${schoolid}`, {
        headers: {
          "x-access-token": `${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      })
      .then((res) => {
        console.log(res.data);

        if (res.status == 200) {
          setProfile(res.data);
        }

        returnStatus = "success";
        returnResponse = "Berhasil";
      })
      .catch((err) => {
        console.log(err);
        returnStatus = "error";
        returnResponse = "Gagal";
        setError(err.toString());
      });

    return {
      status: returnStatus,
      text: returnResponse,
    };
  };

  const handleChange = (prop) => (e) => {
    setProfile({ ...profile, [prop]: e.target.value });
  };

  useEffect(() => {
    getProfile();
  }, []);

  const [studentData, setstudentData] = useState([]);
  const [updateId, setUpdateId] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  const [createModalData, setCreateModalData] = useState({
    email: "",
    name: "",
    nisn: "",
    birthDate: "",
    gender: "",
  });

  const [alert, setAlert] = useState({ type: "", text: "", isOpen: false });

  const sendNewStudent = async () => {
    let returnStatus = "";
    let returnResponse = "";
    console.log(createModalData);
    await api
      .post(
        `admin/students`,
        { ...createModalData, schoolId: schoolid },
        {
          headers: {
            "x-access-token": `${localStorage.getItem("token")}`,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        returnStatus = "success";
        returnResponse = "Book Upload Berhasil";
        if (res.status === 200) {
          setModalOpen(false);
          setUpdateId((s) => s + 1);
        }
      })
      .catch((err) => {
        console.log(err);
        returnStatus = "error";
        returnResponse = "Upload Gagal";
      });

    return {
      status: returnStatus,
      text: returnResponse,
    };
  };

  const handleCreateModalChange = (prop) => (e) => {
    setCreateModalData({ ...createModalData, [prop]: e.target.value });
  };

  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    getProfile();
  }, [updateId]);

  const deleteStudent = (id) => {
    api
      .delete(`admin/students/${id}`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": `${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        setUpdateId((s) => s + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCreateModalDateChange = (newVal) => {
    setCreateModalData({ ...createModalData, birthDate: newVal });
  };

  return (
    <Container maxWidth="lg" sx={{ textAlign: "left" }}>
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={(e, v) => setTabValue(v)}
            aria-label="basic tabs example"
          >
            <Tab label="Profil" />
            <Tab label="Murid" />
            <Tab label="Transaksi" />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <Box>
            <Typography variant="h5" sx={{ marginBottom: "8px" }}>
              Profil Sekolah
            </Typography>
            <Box sx={{ marginBottom: "8px" }}>
              <TextField
                fullWidth
                label="NPSN"
                variant="outlined"
                value={profile?.npsn}
                onChange={handleChange("npsn")}
              />
            </Box>
            <Box sx={{ marginBottom: "8px" }}>
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                value={profile?.email}
                onChange={handleChange("email")}
              />
            </Box>
            <Box sx={{ marginBottom: "8px" }}>
              <TextField
                fullWidth
                label="Nama"
                variant="outlined"
                value={profile?.nama}
                onChange={handleChange("nama")}
              />
            </Box>
            <Box sx={{ marginBottom: "8px" }}>
              <TextField
                fullWidth
                label="Alamat"
                variant="outlined"
                value={profile?.alamat}
                onChange={handleChange("alamat")}
              />
            </Box>
            <Box sx={{ marginBottom: "8px" }}>
              <TextField
                fullWidth
                label="Status"
                variant="outlined"
                value={profile?.status}
                onChange={handleChange("status")}
              />
            </Box>
            <Box sx={{ marginBottom: "8px" }}>
              <TextField
                fullWidth
                label="Jenjang"
                variant="outlined"
                value={profile?.jenjang}
                onChange={handleChange("desaKelurahan")}
              />
            </Box>
            <Box sx={{ marginBottom: "8px" }}>
              <TextField
                fullWidth
                label="Kecamatan"
                variant="outlined"
                value={profile?.kecamatan}
                onChange={handleChange("kecamatan")}
              />
            </Box>
            <Box sx={{ marginBottom: "8px" }}>
              <TextField
                fullWidth
                label="Desa Kelurahan"
                variant="outlined"
                value={profile?.desaKelurahan}
                onChange={handleChange("desaKelurahan")}
              />
            </Box>
            <Box sx={{ marginBottom: "8px" }}>
              <TextField
                fullWidth
                label="Kabupaten/Kota"
                variant="outlined"
                value={profile?.kabupatenKota}
                onChange={handleChange("kabupatenKota")}
              />
            </Box>
            <Box sx={{ marginBottom: "8px" }}>
              <TextField
                fullWidth
                label="Provinsi"
                variant="outlined"
                value={profile?.provinsi}
                onChange={handleChange("provinsi")}
              />
            </Box>
            <Box sx={{ marginBottom: "8px" }}>
              <TextField
                fullWidth
                label="Kode Pos"
                variant="outlined"
                value={profile?.postCode}
                onChange={handleChange("postcode")}
              />
            </Box>
            <Typography variant="h4" sx={{ marginBottom: "8px" }}>
              Kontak Sekolah
            </Typography>
            <Box sx={{ marginBottom: "8px" }}>
              <TextField
                fullWidth
                label="Nomor Telepon"
                variant="outlined"
                value={profile?.phoneNumber}
                onChange={handleChange("phoneNumber")}
              />
            </Box>
            <Box sx={{ marginBottom: "8px" }}>
              <TextField
                fullWidth
                label="Nama Admin"
                variant="outlined"
                value={profile?.adminsName}
                onChange={handleChange("adminsName")}
              />
            </Box>
            <Box sx={{ marginBottom: "8px" }}>
              <TextField
                fullWidth
                label="Nomor Whatsapp Admin"
                variant="outlined"
                value={profile?.adminsWhatsapp}
                onChange={handleChange("adminsWhatsapp")}
              />
            </Box>
            <Box>
              <Typography variant="error" sx={{ marginBottom: "8px" }}>
                {error}
              </Typography>
            </Box>
            <Button
              variant="contained"
              sx={{ marginBottom: "8px" }}
              onClick={() => sendProfile()}
            >
              Save
            </Button>
          </Box>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Box sx={{ marginBottom: "8px" }}>
            <Button variant="outlined" onClick={() => setModalOpen(true)}>
              Tambah Data Murid
            </Button>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={modalOpen}
              onClose={() => {
                setModalOpen(false);
              }}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={modalOpen}>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                  }}
                >
                  <Typography variant="h6" component="h2">
                    Tambah data murid
                  </Typography>

                  <Box sx={{ marginBottom: "8px" }}>
                    <TextField
                      fullWidth
                      type="email"
                      label="Email Murid"
                      variant="outlined"
                      value={createModalData?.email}
                      onChange={handleCreateModalChange("email")}
                    />
                  </Box>
                  <Box sx={{ marginBottom: "8px" }}>
                    <TextField
                      fullWidth
                      label="Nama Murid"
                      variant="outlined"
                      value={createModalData?.name}
                      onChange={handleCreateModalChange("name")}
                    />
                  </Box>

                  <Box sx={{ marginBottom: "8px" }}>
                    <TextField
                      fullWidth
                      label="NISN"
                      variant="outlined"
                      value={createModalData?.nisn}
                      onChange={handleCreateModalChange("nisn")}
                    />
                  </Box>

                  {/* <Box sx={{ marginBottom: "8px" }}>
                <TextField
                  fullWidth
                  label="Tanggal Lahir"
                  variant="outlined"
                  type="datetime"
                />
              </Box> */}

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Basic example"
                      value={createModalData?.birthDate}
                      onChange={handleCreateModalDateChange}
                      renderInput={(params) => (
                        <TextField fullWidth {...params} />
                      )}
                    />
                  </LocalizationProvider>

                  {/* <Box sx={{ marginBottom: "8px" }}>
                <TextField
                  fullWidth
                  label="Jenis Kelamin"
                  variant="outlined"
                  value={createModalData?.gender}
                  onChange={handleCreateModalChange("gender")}
                />
              </Box> */}
                  <Button onClick={() => sendNewStudent()} variant="contained">
                    Submit
                  </Button>
                  <Button onClick={() => setModalOpen(false)} variant="text">
                    Batal
                  </Button>
                </Box>
              </Fade>
            </Modal>
            <TableContainer container="div">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>NISN</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell
                      className={css`
                        width: max-content;
                      `}
                    >
                      Nama
                    </TableCell>
                    <TableCell>Tanggal Lahir</TableCell>
                    <TableCell>Status Akun</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                {profile?.studentData?.map((i, k) => (
                  <TableRow key={k}>
                    <TableCell
                      className={css`
                        width: max-content;
                        white-space: nowrap;
                      `}
                    >
                      {i.nisn}
                    </TableCell>
                    <TableCell
                      className={css`
                        width: max-content;
                        white-space: nowrap;
                      `}
                    >
                      {i.email}
                    </TableCell>
                    <TableCell
                      className={css`
                        width: max-content;
                        white-space: nowrap;
                      `}
                    >
                      {i.name}
                    </TableCell>

                    <TableCell
                      className={css`
                        width: max-content;
                        white-space: nowrap;
                      `}
                    >
                      {i.birthDate &&
                        format(new Date(i.birthDate), "dd MMM yyyy")}
                    </TableCell>
                    <TableCell
                      className={css`
                        width: max-content;
                        white-space: nowrap;
                      `}
                    >
                      {i.isActive ? "Aktif" : "Inaktif"}
                    </TableCell>
                    <TableCell>
                      <span
                        className={css`
                          display: flex;
                          gap: 4px;
                        `}
                      >
                        <Button
                          variant="outlined"
                          color="error"
                          startIcon={<DeleteIcon />}
                          onClick={() => deleteStudent(i._id)}
                        >
                          Delete
                        </Button>
                      </span>
                    </TableCell>
                    {/* <TableCell><p>{JSON.stringify(i)}</p></TableCell> */}
                  </TableRow>
                ))}
              </Table>
            </TableContainer>
          </Box>
        </TabPanel>

        <TabPanel value={tabValue} index={2}>
          <Typography>Riwayat Transaksi</Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Waktu Transaksi</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Harga</TableCell>
                </TableRow>
              </TableHead>
              {profile?.transactions?.map((t) => (
                <TableRow>
                  <TableCell>
                    {t.createdAt &&
                      format(new Date(t.createdAt), "dd MMM yyyy HH:mm:ss")}
                  </TableCell>
                  <TableCell>{t.isPaid ? "LUNAS" : "PENDING"}</TableCell>
                  <TableCell>{`Rp ${t.price.toLocaleString()}`}</TableCell>
                </TableRow>
              ))}
            </Table>
          </TableContainer>
        </TabPanel>
      </Box>
    </Container>
  );
}

export default ProfilePage;
