import logo from "./logo.svg";
import "./App.scss";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  NavLink,
} from "react-router-dom";

import { Box, Container, ThemeProvider } from "@mui/material";
import LoadingOverlay from 'react-loading-overlay-ts';
import { SnackbarProvider, useSnackbar } from 'notistack';

import BookUpload from "./pages/admin/AdminBookUpload.jsx";
import BookPage from "./pages/admin/AdminBookPage.jsx";
import AdminSchoolPage from "./pages/admin/AdminSchoolPage.jsx";
import AdminPaymentValidationPage from "./pages/admin/AdminPaymentValidationPage.jsx";
import AdminPage from "./pages/admin/AdminPage.jsx";
import AdminLoginPage from "./pages/admin/LoginPage.jsx";
import AdminBookEditPage from "./pages/admin/AdminBookEditPage.jsx";
import AdminSchoolEditPage from "./pages/admin/AdminSchoolEditPage.jsx";

import SchoolBookCatalogue from "./pages/school/SchoolBookCatalogue.jsx";
import SchoolBookPage from "./pages/school/SchoolBookPage.jsx";
import SchoolRegistration from "./pages/school/RegistrationPage.jsx";
import SchoolConfirmation from "./pages/school/EmailConfirmationPage.jsx";
import SchoolProfile from "./pages/school/ProfilePage.jsx";
import SchoolStudents from "./pages/school/StudentPage.jsx";
import SchoolLogin from "./pages/school/LoginPage.jsx";
import SchoolPage from "./pages/school/SchoolPage.jsx";
import SchoolBuyBooksPage from "./pages/school/BuyBooksPage.jsx";
import SchoolBookReader from "./pages/school/SchoolBookReader.jsx";
import SchoolForgotPassword from "./pages/school/ForgotPasswordPage.jsx";
import SchoolChangePassword from "./pages/school/PasswordChangePage.jsx";

import StudentLogin from "./pages/student/LoginPage.jsx";
import StudentPage from "./pages/student/StudentPage.jsx";
import StudentBookPage from "./pages/student/BookPage.jsx";
import StudentFreeBookPage from "./pages/student/FreeBookPage.jsx";
import StudentRegistration from "./pages/student/RegistrationPage.jsx";
import StudentConfirmation from "./pages/student/EmailConfirmationPage.jsx";
import StudentProfile from "./pages/student/ProfilePage.jsx";
import StudentBookReader from "./pages/student/BookReader.jsx";
import StudentForgotPasswordPage from "./pages/student/ForgotPasswordPage.jsx";
import StudentChangePasswordPage from "./pages/student/PasswordChangePage.jsx";

import Home from "./pages/landing/Home.jsx";
import LoginPage from "./pages/LoginPage.jsx";
import ErrorPage from "./pages/ErrorPage";

import theme from "./theme";
import AdminSchoolRegistrationPage from "./pages/admin/AdminSchoolRegistrationPage";

import useLoginStatus from "./utils/useLoginStatus";
import TransactionHistoryPage from "./pages/school/TransactionHistoryPage";
import TransactionDetailPage from "./pages/school/TransactionDetailPage";
import { connect, useDispatch } from "react-redux";
import { setLoader } from "./store";

function App(props) {
  const [isLoggedIn, login, logout] = useLoginStatus();

  const linkStyle = {
    "& a": {
      textDecorationLine: "none",
      textDecoration: "none",
      borderRadius: "2px",
      padding: "4px",
      transition: "0.5s",
    },
    "& a.active": {
      color: "#D09D32!important",
      backgroundColor: "#dedede!important",
    },
  };

  const dispatch = useDispatch();

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <LoadingOverlay
          active={props.isActive}
          spinner
          text="Loading..."
        >
          <div className="App">
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link
              rel="preconnect"
              href="https://fonts.gstatic.com"
              crossOrigin="true"
            />
            <link
              href="https://fonts.googleapis.com/css2?family=Dongle&display=swap"
              rel="stylesheet"
            />
            <link
              rel="stylesheet"
              href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"
              integrity="sha512-Fo3rlrZj/k7ujTnHg4CGR2D7kSs0v4LLanw2qksYuRlEzO+tcaEPQogQ0KaoGN26/zrn20ImR1DfuLWnOo7aBA=="
              crossOrigin="anonymous"
              referrerPolicy="no-referrer"
            />
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="/apple-touch-icon.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href="/favicon-32x32.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href="/favicon-16x16.png"
            />
            <link rel="manifest" href="/site.webmanifest"></link>
            <Router>
              <Box
                sx={{
                  background: "#fafafa",
                  height: "80px",
                  position: "sticky",
                  top: "0",
                  zIndex: "100000",
                }}
              >
                <Container
                  sx={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "right",
                    color: "#C98E0B",
                    "& :not(:first-child)": { marginLeft: "8px" },
                  }}
                >
                  <Box
                    sx={{
                      height: { xs: "70%", sm: "100%" },
                      padding: "4px 0",
                      marginRight: "auto",
                      "& > a > img": { height: "100%" },
                    }}
                  >
                    <a href="/">
                      <img src="/logo.png" alt="logo" />
                    </a>
                  </Box>
                  {isLoggedIn ? (
                    <>
                      <Box sx={linkStyle}>
                        <NavLink to="/" onClick={() => logout()} sx={linkStyle}>
                          LOGOUT
                        </NavLink>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box sx={linkStyle}>
                        <NavLink to="school/login">LOGIN SEKOLAH</NavLink>
                      </Box>
                      <Box sx={linkStyle}>
                        <NavLink to="student/login">LOGIN MURID</NavLink>
                      </Box>
                    </>
                  )}
                </Container>
              </Box>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route
                  path="admin/login"
                  element={<AdminLoginPage loginHook={login.bind(this)} />}
                />
                <Route path="admin/" element={<AdminPage />}>
                  <Route path="books" element={<BookPage />} />
                  <Route path="books/upload" element={<BookUpload />} />
                  <Route path="books/:bookid" element={<AdminBookEditPage />} />
                  <Route
                    path="validation"
                    element={<AdminPaymentValidationPage />}
                  />
                  <Route path="schools" element={<AdminSchoolPage />} />
                  <Route
                    path="schools/register"
                    element={<AdminSchoolRegistrationPage />}
                  />
                  <Route
                    path="schools/:schoolid"
                    element={<AdminSchoolEditPage />}
                  />
                </Route>
                <Route path="school/register" element={<SchoolRegistration />} />
                <Route
                  path="school/login"
                  element={<SchoolLogin loginHook={login.bind(this)} />}
                />
                <Route
                  path="school/forgotpassword"
                  element={<SchoolForgotPassword />}
                />
                <Route
                  path="school/confirmation/:email/:confirmationToken"
                  element={<SchoolConfirmation />}
                />
                <Route
                  path="school/forgotpassword/:email/:confirmationToken"
                  element={<SchoolChangePassword />}
                />
                <Route path="school/" element={<SchoolPage />}>
                  <Route path="profile" element={<SchoolProfile />} />
                  <Route path="books" element={<SchoolBookCatalogue />} />
                  <Route path="mybooks" element={<SchoolBookPage />} />
                  <Route path="read" element={<SchoolBookReader />} />
                  <Route path="students" element={<SchoolStudents />} />
                  <Route path="buybooks" element={<SchoolBuyBooksPage />} />
                  <Route path="transactions" element={<TransactionHistoryPage />} />
                  <Route path="transactions/:id" element={<TransactionDetailPage />} />
                </Route>

                <Route path="student/register" element={<StudentRegistration />} />
                <Route
                  path="student/login"
                  element={<StudentLogin loginHook={login.bind(this)} />}
                />
                <Route
                  path="student/confirmation/:email/:confirmationToken"
                  element={<StudentConfirmation />}
                />
                <Route
                  path="student/forgotpassword"
                  element={<StudentForgotPasswordPage />}
                />
                <Route
                  path="student/forgotpassword/:email/:confirmationToken"
                  element={<StudentChangePasswordPage />}
                />
                <Route path="student/" element={<StudentPage />}>
                  <Route path="profile" element={<StudentProfile />} />
                  <Route path="mybooks" element={<StudentBookPage />} />
                  <Route path="read" element={<StudentBookReader />} />
                  <Route path="freebooks" element={<StudentFreeBookPage />} />
                </Route>
                <Route path="login" element={<LoginPage />} />
                <Route path="*" element={<ErrorPage />} />
              </Routes>
            </Router>
          </div>

        </LoadingOverlay>
      </SnackbarProvider>
      

    </ThemeProvider>
  );
}

const mapStateToProps = state => {
  return {
    isActive: state.isLoading
  }
}

export default connect(mapStateToProps)(App);
