import axios from "axios";
import config from "../config";


import { store, setLoader } from "../store";


const Api = axios.create({
  baseURL: config.baseUrl,
  timeout: config.timeout,
  headers: {},
});

Api.interceptors.request.use(
  req => {
    store.dispatch(setLoader(true));

    return req
  },
  err => {
    store.dispatch(setLoader(false));

    return Promise.reject(err);
  }
)

Api.interceptors.response.use(
  res => {
    store.dispatch(setLoader(false));

    return res
  },
  err => {
    store.dispatch(setLoader(false));

    return Promise.reject(err);
  }
)

export default Api;
