import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Tabs,
  Tab,
  Box,
  Typography,
  Container,
  TextField,
  Button,
  Link,
} from "@mui/material";

import api from "../../service/api";
import useAuth from "../../service/auth";
import { useDispatch } from "react-redux";
import { setLoader } from "../../store";

import { useSnackbar } from "notistack";

function App({ loginHook }) {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();

  const navigate = useNavigate();

  const { login } = useAuth();

  const { enqueueSnackbar } = useSnackbar();

  const sendLogin = async () => {
    var returnStatus, returnResponse;
    await api
      .post(
        `admin/login`,
        { email: email, password: password },
        {
          headers: {},
        }
      )
      .then((res) => {
        console.log(res.data);

        if (res.status == 200) {
          localStorage.setItem("token", res.data.token);
          loginHook();
          login("admin").then(() => {
            navigate("/admin/books", { replace: true });
          });
        }

        returnStatus = "success";
        returnResponse = "Berhasil";

        enqueueSnackbar("Login Sukses", { variant: "success"} );
      })
      .catch((err) => {
        console.log(err);
        returnStatus = "error";
        returnResponse = "Gagal";
        setError(err.toString());

        enqueueSnackbar("Login Gagal", { variant: "error"} );
      });

    return {
      status: returnStatus,
      text: returnResponse,
    };
  };

  const dispatch = useDispatch();

  return (
    <Container maxWidth="lg" sx={{ textAlign: "center" }}>
      <Box>
      <button onClick={()=>{
          dispatch(setLoader(true));
          setTimeout(() => {
            dispatch(setLoader(false));
          }, 20000);

        }}>Load spinner</button>
        <Typography variant="h3" sx={{ marginBottom: "8px" }}>
          Admin Login
        </Typography>
        <Box sx={{ marginBottom: "8px" }}>
          <TextField
            label="Email"
            variant="outlined"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </Box>
        <Box sx={{ marginBottom: "8px" }}>
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </Box>
        {/* <Box>
          <Typography variant="error" sx={{ marginBottom: "8px" }}>
            {error}
          </Typography>
        </Box> */}
        <Button
          variant="contained"
          sx={{ marginBottom: "8px" }}
          onClick={() => sendLogin()}
        >
          Login
        </Button>
        {/* <Box>
          <Typography variant="p">
            Lupa password? <Link href="#">Kirim email konfirmasi.</Link>
          </Typography>
        </Box>
        <Box>
          <Typography variant="p">
            Belum terdaftar? <Link href="#">Register.</Link>
          </Typography>
        </Box> */}
      </Box>
    </Container>
  );
}

export default App;
