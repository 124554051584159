import { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  Snackbar,
  Checkbox,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  FormGroup,
} from "@mui/material";

import { css, cx } from "@emotion/css";

import api from "../../service/api";
import { useParams } from "react-router-dom";
import BookReader from "../../components/BookReader";

const BookUpload = () => {
  const [bookData, setBookData] = useState({
    title: "",
    author: "",
    ISBN: "",
    schoolLevel: "",
    description: "",
    publisher: "",
    publishYear: "",
    numPages: "",
    weight: "",
    dimensions: "",
    pricePerSemester: "",
  });

  const [bookFile, setBookFile] = useState({ file: undefined });

  const [alert, setAlert] = useState({ type: "", text: "", isOpen: false });

  const { bookid } = useParams();

  const getBookData = () => {
    api
      .get(`admin/books/${bookid}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-access-token": `${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        console.log(res.data);

        setBookData(res.data);
        localStorage.setItem("bookid", res.data._id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveBookData = () => {
    let formData = new FormData();

    // if (!(bookFile?.file && bookFile.file.length > 0)) {
    //   return {
    //     status: "error",
    //     text: "File belum terpilih",
    //   };
    // }

    // formData.append("file", bookFile.file[0]);
    formData.append("data", JSON.stringify(bookData));
    console.log(bookData);
    let returnStatus = "";
    let returnResponse = "";
    api
      .put(`admin/books/${bookid}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-access-token": `${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        returnStatus = "success";
        returnResponse = "Book Upload Berhasil";

        setAlert((a) => ({
          type: returnStatus,
          text: returnResponse,
          isOpen: true,
        }));
        getBookData();
      })
      .catch((err) => {
        console.log(err);
        returnStatus = "error";
        returnResponse = "Upload Gagal";

        setAlert((a) => ({
          type: returnStatus,
          text: returnResponse,
          isOpen: true,
        }));
      });

    return {
      status: returnStatus,
      text: returnResponse,
    };
  };

  const handleChange = (prop) => (e) => {

    if (prop === "isDraft" || prop === "isFree") {
      setBookData({ ...bookData, [prop]: e.target.checked});
      return;
    }

    setBookData({ ...bookData, [prop]: e.target.value });
  };

  useEffect(() => {
    getBookData();
  }, []);

  return (
    <>
      <Typography variant="h5" gutterBottom component="div">
        Edit Buku
      </Typography>
      <Box sx={{ width: "50vh" }}>
        {localStorage.getItem("bookid") ? (
          <BookReader
            bookid={localStorage.getItem("bookid")}
            initialPage={1}
            userType="admin"
          />
        ) : (
          "Tidak ada buku terpilih"
        )}
      </Box>

      <small>(hapus buku untuk mengganti pdf)</small>
      <Box>
        <label htmlFor="contained-button-file">
          <Button variant="contained" component="span" disabled>
            Upload File
          </Button>
          <input
            disabled
            accept="*"
            id="contained-button-file"
            type="file"
            className={css`
              display: none;
            `}
            onChange={(event) => {
              setBookFile((f) => ({ file: event.target.files }));
              console.log(event.target.files);
              console.log(bookFile);
            }}
          />
        </label>
        <Box style={{ display: "block", marginTop: "0.5em" }}>
          {bookFile.file && bookFile.file[0] && (
            <li>
              <span>
                {bookFile.file[0].name} - {`${bookFile.file[0].size} bytes`}
              </span>
              <span
                className={css`
                  color: red;
                  float: right;
                `}
                onClick={() => {
                  setBookFile({ file: undefined });
                }}
              >
                X
              </span>
            </li>
          )}
        </Box>
      </Box>
      <hr
        className={css`
          width: 90%;
          border-color: #efefef;
        `}
      />
      <Typography variant="h5" gutterBottom component="div">
        Data Buku
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          gap: "12px",
          p: "12px",
          m: 0,
        }}
      >
        <Typography
          variant="h6"
          gutterBottom
          component="div"
          sx={{ flex: "0 0 100%" }}
        >
          Informasi Umum
        </Typography>
        <TextField
          required
          id="bookupload-title"
          label="Judul"
          variant="outlined"
          value={bookData?.title}
          onChange={handleChange("title")}
          sx={{ flex: "0 0 100%" }}
          disabled
        />
        <TextField
          required
          id="bookupload-title"
          label="Penulis"
          variant="outlined"
          value={bookData?.author}
          onChange={handleChange("author")}
          sx={{ flex: "0 0 100%" }}
        />

        <TextField
          required
          id="bookupload-ISBN"
          label="ISBN"
          variant="outlined"
          value={bookData?.ISBN}
          onChange={handleChange("ISBN")}
          sx={{ flex: "0 0 100%" }}
          disabled
        />

        <Typography
          variant="h6"
          gutterBottom
          component="div"
          sx={{ flex: "0 0 100%" }}
        >
          Penerbit
        </Typography>
        <TextField
          required
          id="bookupload-publisher"
          label="Penerbit"
          variant="outlined"
          value={bookData?.publisher}
          onChange={handleChange("publisher")}
          sx={{ flex: "0 0 100%" }}
        />

        <TextField
          required
          id="bookupload-publishYear"
          label="Tahun Terbit"
          variant="outlined"
          value={bookData?.publishYear}
          onChange={handleChange("publishYear")}
          sx={{ flex: "0 0 100%" }}
        />

        <TextField
          required
          id="bookupload-price"
          label="Harga Per Tahun"
          variant="outlined"
          value={bookData?.pricePerSemester}
          onChange={handleChange("pricePerSemester")}
          sx={{ flex: "0 0 100%" }}
        />

        <Typography
          variant="h6"
          gutterBottom
          component="div"
          sx={{ flex: "0 0 100%" }}
        >
          Detail Lainnya
        </Typography>
        <TextField
          required
          id="bookupload-desc"
          label="Deskripsi"
          variant="outlined"
          value={bookData?.description}
          onChange={handleChange("description")}
          sx={{ flex: "0 0 100%" }}
        />
        <FormControl fullWidth>
          <InputLabel required>Jenjang</InputLabel>
          <Select
            id="bookupload-schoollevel"
            label="Jenjang"
            variant="outlined"
            value={bookData?.schoolLevel}
            onChange={handleChange("schoolLevel")}
            sx={{ flex: "0 0 100%" }}
          >
            <MenuItem value={"SMA/MA"}>SMA/MA</MenuItem>
            <MenuItem value={"SMP/MTs"}>SMP/MTs</MenuItem>
            <MenuItem value={"SD/MI"}>SD/MI</MenuItem>
            <MenuItem value={"Mandiri"}>Mandiri</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth>
          { bookData.isDraft !== undefined ?
          <FormControlLabel fullWidth control={
            <Checkbox checked={bookData.isDraft === true} onChange={handleChange("isDraft")}/>
          } label="Draft" /> : ''}

          { bookData.isDraft !== undefined ? <FormControlLabel fullWidth control={
            <Checkbox checked={bookData?.isFree} onChange={handleChange("isFree")}/>
          }  label="Gratis Akses"/> : ''}
        </FormControl>

        <Button
          variant="contained"
          onClick={() => {
            let { status, text } = saveBookData();
          }}
        >
          Simpan Perubahan
        </Button>
        <Button variant="text">Batal</Button>
      </Box>
      <Snackbar
        open={alert?.isOpen}
        autoHideDuration={1000}
        onClose={() => setAlert((a) => ({ ...a, isOpen: false }))}
        message={alert.text}
      ></Snackbar>
    </>
  );
};

export default BookUpload;
