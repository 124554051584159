import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Table,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  Box,
  Typography,
  Container,
  TextField,
  Button,
  Link,
} from "@mui/material";

import { css, cx } from "@emotion/css";

import api from "../../service/api";
import config from "../../config";

function BuyBooksPage() {
  const [buyData, setBuyData] = useState({
    isbns: "",
    nisns: "",
    duration: "",
  });

  const [buyDraftData, setBuyDraftData] = useState("");
  const [error, setError] = useState();

  const navigate = useNavigate();

  const sendBulkBuyDraft = async () => {
    var returnStatus, returnResponse;
    await api
      .post(
        `school/bulkbooksdraft`,
        {
          isbns: buyData.isbns.split(",").map((a) => a.trim()),
          nisns: buyData.nisns.split(",").map((a) => a.trim()),
          duration: buyData.duration,
        },
        {
          headers: {
            "x-access-token": `${localStorage.getItem("token")}`,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept",
          },
        }
      )
      .then((res) => {
        console.log(res.data);

        if (res.status == 200) {
          // Success
          setBuyDraftData(res.data);
        }

        returnStatus = "success";
        returnResponse = "Berhasil";
      })
      .catch((err) => {
        console.log(err);
        returnStatus = "error";
        returnResponse = "Gagal";
        setError(err.toString());
      });

    return {
      status: returnStatus,
      text: returnResponse,
    };
  };
  const sendBulkBuy = async () => {
    var returnStatus, returnResponse;
    await api
      .post(
        `school/bulkbooks`,
        {
          isbns: buyData.isbns.split(",").map((a) => a.trim()),
          nisns: buyData.nisns.split(",").map((a) => a.trim()),
          duration: buyData.duration,
        },
        {
          headers: {
            "x-access-token": `${localStorage.getItem("token")}`,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept",
          },
        }
      )
      .then((res) => {
        console.log(res.data);

        if (res.status == 200) {
          // Success
          setBuyDraftData(false);
          setBuyData({
            isbns: "",
            nisns: "",
            duration: "",
          });
        }

        returnStatus = "success";
        returnResponse = "Berhasil";
      })
      .catch((err) => {
        console.log(err);
        returnStatus = "error";
        returnResponse = "Gagal";
        setError(err.toString());
      });

    return {
      status: returnStatus,
      text: returnResponse,
    };
  };

  const getProfile = async () => {
    var returnStatus, returnResponse;
    await api
      .get(`school/profile`, {
        headers: {
          "x-access-token": `${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      })
      .then((res) => {
        console.log(res.data);

        if (res.status == 200) {
          // setProfile(res.data);
        }

        returnStatus = "success";
        returnResponse = "Berhasil";
      })
      .catch((err) => {
        console.log(err);
        returnStatus = "error";
        returnResponse = "Gagal";
        setError(err.toString());
      });

    return {
      status: returnStatus,
      text: returnResponse,
    };
  };

  const handleChange = (prop) => (e) => {
    setBuyData({ ...buyData, [prop]: e.target.value });
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <>
      <Box sx={{ width: { xs: "100%", lg: "50%" }, p: "12px" }}>
        <Box>
          <Typography variant="h5" sx={{ marginBottom: "8px" }}>
            Detail Buku
          </Typography>
          <Box sx={{ marginBottom: "8px" }}>
            <TextField
              fullWidth
              label="List ISBN"
              variant="outlined"
              value={buyData?.isbns}
              onChange={handleChange("isbns")}
            />
            <Typography sx={{ marginBottom: "8px" }}>
              Pisahkan antar ISBN dengan koma.
              <br />
              Contoh: 978-623-98229-2-7, 978-623-98229-0-3
            </Typography>
          </Box>
          <Typography variant="h5" sx={{ marginBottom: "8px" }}>
            List Murid
          </Typography>
          <Box sx={{ marginBottom: "8px" }}>
            <TextField
              fullWidth
              label="List NISN"
              variant="outlined"
              value={buyData?.nisns}
              onChange={handleChange("nisns")}
            />
            <Typography sx={{ marginBottom: "8px" }}>
              Pisahkan antar NISN dengan koma.
              <br />
              Contoh: 12345678, 12345679
            </Typography>
          </Box>
          <Typography variant="h5" sx={{ marginBottom: "8px" }}>
            Detail Langganan
          </Typography>
          <Box sx={{ marginBottom: "8px", display: "flex" }}>
            <TextField
              label="Durasi Langganan"
              variant="outlined"
              value={buyData?.duration}
              onChange={handleChange("duration")}
            />
            <Box
              sx={{ display: "flex", alignItems: "center", marginLeft: "8px" }}
            >
              <Typography sx={{ marginBottom: "8px" }}>
                Tahun
              </Typography>
            </Box>
          </Box>

          <Button
            variant="contained"
            sx={{ marginBottom: "8px" }}
            onClick={() => sendBulkBuyDraft()}
          >
            Save
          </Button>
        </Box>
        {buyDraftData ? (
          <>
            <Box>
              <Typography variant="h5">Detil Pembelian Buku</Typography>

              <TableContainer container="div">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nomor</TableCell>
                      <TableCell>Buku</TableCell>
                      <TableCell>Harga Per Tahun</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  {buyDraftData?.books?.map((i, v) => (
                    <TableRow>
                      <TableCell>{v + 1}</TableCell>
                      <TableCell>
                        <img
                          src={`${config.baseUrl}/school/books/${i._id}/thumb`}
                          alt={`${i.title}`}
                          className={css`
                            height: 100px;
                          `}
                        />
                      </TableCell>
                      <TableCell>{i.ISBN}</TableCell>
                      <TableCell>{i.title}</TableCell>
                      <TableCell>
                        Rp {i?.pricePerSemester.toLocaleString()}
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              </TableContainer>
              <Typography variant="h5">Detil Murid</Typography>

              <TableContainer container="div">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nomor</TableCell>
                      <TableCell>NISN</TableCell>
                      <TableCell>Nama Murid</TableCell>
                      <TableCell>Email</TableCell>
                    </TableRow>
                  </TableHead>
                  {buyDraftData?.students?.map((i, v) => (
                    <TableRow>
                      <TableCell>{v + 1}</TableCell>
                      <TableCell>{i.nisn}</TableCell>
                      <TableCell>{i.name}</TableCell>
                      <TableCell>{i.email}</TableCell>
                    </TableRow>
                  ))}
                </Table>
              </TableContainer>

              <Typography variant="h5">Detil Pembayaran</Typography>

              <TableContainer container="div">
                <Table>
                  {buyDraftData?.transaction ? (
                    <>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Subtotal Per Murid Per Tahun</TableCell>
                        <TableCell>
                          Rp{" "}
                          {buyDraftData.transaction.pricePerStudentPerSemester.toLocaleString()}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>
                          Subtotal Per Murid (Jumlah murid:{" x"}
                          {buyDraftData.students.length})
                        </TableCell>
                        <TableCell>
                          Rp{" "}
                          {buyDraftData.transaction.pricePerSemester.toLocaleString()}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>
                          Total (Durasi: {buyDraftData.transaction.duration})
                        </TableCell>
                        <TableCell>
                          Rp {buyDraftData.transaction.price.toLocaleString()}
                        </TableCell>
                      </TableRow>
                    </>
                  ) : (
                    false
                  )}
                </Table>
              </TableContainer>
              <Box>
                <Box>
                  <Typography variant="h5">Teknis Pembayaran</Typography>
                </Box>
                <Box>
                  <Typography variant="p">
                    Setelah mengklik Konfirmasi pembelian, silahkan transfer
                    jumlah yang ditentukan ke rekening BNI: <br /> <br />
                    BNI
                    <br />
                    1134 805031
                    <br />
                    A.n PT.Payung Cerdas Indonesia
                    <br /> dalam paling lambat 3 hari.
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  sx={{ marginBottom: "8px" }}
                  onClick={() => sendBulkBuy()}
                >
                  Konfirmasi Pembelian
                </Button>
              </Box>
            </Box>{" "}
          </>
        ) : (
          false
        )}
      </Box>
    </>
  );
}

export default BuyBooksPage;
