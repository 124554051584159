import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

import { css, cx } from "@emotion/css";

import api from "../../service/api";

const ValidationPage = () => {
  const navigate = useNavigate();
  const [transactionData, setTransactionData] = useState([]);
  const [updateId, setUpdateId] = useState(0);

  const [myAlert, setMyAlert] = useState({ type: "", text: "", isOpen: false });

  const getTransactionData = async () => {
    let returnStatus = "";
    let returnResponse = "";
    await api
      .get(`admin/transactions`, {
        headers: {
          "x-access-token": `${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      })
      .then((res) => {
        console.log(res.data);
        setTransactionData((d) => res.data.transactions);
        returnStatus = "success";
        returnResponse = "Book Upload Berhasil";
      })
      .catch((err) => {
        console.log(err);
        returnStatus = "error";
        returnResponse = "Upload Gagal";
      });

    return {
      status: returnStatus,
      text: returnResponse,
    };
  };

  const validateTransaction = (i) => {
    api
      .post(
        `admin/transactions/${i._id}/validate`,
        {},
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "x-access-token": `${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setUpdateId((s) => s + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTransactionData();
  }, [updateId]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          gap: "12px",
          p: "12px",
          m: 0,
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          sx={{ flex: "0 0 100%" }}
        >
          List Transaksi
        </Typography>

        <TableContainer container="div">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Tanggal Pembelian</TableCell>
                <TableCell>Email Sekolah</TableCell>
                <TableCell>Jumlah Pembayaran</TableCell>
                <TableCell>Status Pembayaran</TableCell>
              </TableRow>
            </TableHead>
            {transactionData
              .sort((a, b) => {
                return -(
                  +new Date(a.transaction.createdAt) -
                  +new Date(b.transaction.createdAt)
                );
              })
              .map((i) => (
                <TableRow>
                  <TableCell>
                    {new Date(i.transaction?.createdAt).toLocaleDateString()}
                  </TableCell>
                  <TableCell
                    className={css`
                      width: 100px;
                    `}
                  >
                    {i.school.email}
                  </TableCell>

                  <TableCell>
                    Rp {i.transaction?.price.toLocaleString()}
                  </TableCell>
                  <TableCell>{i.transaction?.isPaid?.toString()}</TableCell>

                  <TableCell>
                    <span
                      className={css`
                        display: flex;
                        gap: 4px;
                      `}
                    >
                      {i.transaction?.isPaid ? (
                        <Button
                          variant="outlined"
                          startIcon={<CloseIcon />}
                          onClick={() => {
                            alert("Kontak admin PCI");
                          }}
                        >
                          Stop Akses
                        </Button>
                      ) : (
                        <Button
                          variant="outlined"
                          color="success"
                          startIcon={<CheckIcon />}
                          onClick={() => {
                            api
                              .post(
                                `admin/transactions/${i.transaction._id}/validate`,
                                {},
                                {
                                  headers: {
                                    "Content-Type": "multipart/form-data",
                                    "x-access-token": `${localStorage.getItem(
                                      "token"
                                    )}`,
                                  },
                                }
                              )
                              .then((res) => {
                                console.log(res.data);
                                setUpdateId((s) => s + 1);
                              })
                              .catch((err) => {
                                console.log(err);
                              });
                          }}
                        >
                          Validasi Akses
                        </Button>
                      )}
                    </span>
                  </TableCell>
                  {/* <TableCell><p>{JSON.stringify(i)}</p></TableCell> */}
                </TableRow>
              ))}
          </Table>
        </TableContainer>
      </Box>

      <Snackbar
        open={myAlert?.isOpen}
        autoHideDuration={1000}
        onClose={() => setMyAlert((a) => ({ ...a, isOpen: false }))}
        message={myAlert.text}
      ></Snackbar>
    </>
  );
};

export default ValidationPage;
