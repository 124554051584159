import { useState } from "react";

import {
  Route,
  Routes,
  NavLink,
  Outlet,
  matchPath,
  useLocation,
} from "react-router-dom";

import {
  Tabs,
  Tab,
  Typography,
  Container,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  Button,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SchoolIcon from "@mui/icons-material/School";

import theme from "../../theme";
import { useRef } from "react";

function App() {
  const location = useLocation();
  const navListRef = useRef();

  const navs = [
    { to: "books", icon: <MenuBookIcon />, text: "Koleksi Buku" },
    { to: "books/upload", icon: <UploadFileIcon />, text: "Upload Buku" },
    {
      to: "schools",
      icon: <SchoolIcon />,
      text: "Daftar Sekolah",
    },
    {
      to: "schools/register",
      icon: <AccountBalanceIcon />,
      text: "Registrasi Sekolah",
    },
    {
      to: "validation",
      icon: <AccountBalanceIcon />,
      text: "Pembayaran",
    },
  ];

  const navList = (ref) => (
    <List
      ref={ref}
      sx={{
        visible: { xs: "none", sm: "visible" },
        position: "sticky",
        "& a": {
          color: "black",
        },
      }}
    >
      {navs.map((n) => (
        <ListItem to={n.to} component={NavLink} end>
          <ListItemIcon>{n.icon}</ListItemIcon>
          <ListItemText primary={n.text} />
        </ListItem>
      ))}
    </List>
  );

  const [xsMenu, setXsMenu] = useState({ open: false, anchorEl: null });

  const baseUrl = "/admin/";
  const currentLocation = navs.find(
    (n) => location.pathname === baseUrl + n.to
  );

  return (
    <Box>
      <Box sx={{ visibility: "none", textAlign: "center" }}>
        <Button
          aria-expanded={xsMenu.open || undefined}
          onClick={(e) =>
            setXsMenu((a) => ({
              open: !a.open,
              anchorEl: !a.open ? e.target : null,
            }))
          }
          sx={{
            display: "flex",
            gap: "0.5em",
            alignItems: "center",
            marginLeft: "auto",
            marginRight: "auto",
            visibility: { xs: "visible", sm: "hidden" },
            height : { xs: 'auto', sm: 0},
          }}
        >
          {currentLocation?.icon}
          {currentLocation?.text || "Navigasi"}
          <ArrowDropDownIcon />
        </Button>
        <Menu
          open={xsMenu.open}
          anchorEl={xsMenu.anchorEl}
          onClose={() =>
            setXsMenu((a) => ({
              open: false,
              anchorEl: null,
            }))
          }
          onClick={() =>
            setXsMenu((a) => ({
              open: false,
              anchorEl: null,
            }))
          }
        >
          {navList(navListRef)}
        </Menu>
      </Box>
      <Box
        sx={{
          width: "240px",
          bgcolor: `${theme.palette.background.paper}`,
          position: "fixed",
          height: "100%",
          visibility: { xs: "hidden", sm: "visible" },
        }}
      >
        <nav>{navList(navListRef)}</nav>
      </Box>
      <Box sx={{ marginLeft: { xs: "0", sm: "240px" } }}>
        <Box sx={{ padding: "0.2em" }}>
          <Box
            sx={{
              margin: "auto",
              width: "calc(100% - 2em)",
              borderBottom: "solid 1px #dddddd",
            }}
          >
            <Typography>Dasbor Admin</Typography>
          </Box>
        </Box>

        <Container maxWidth="lg">
          <Outlet />
        </Container>
      </Box>
    </Box>
  );
}

export default App;
