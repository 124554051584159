import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Tabs,
  Tab,
  Box,
  Typography,
  Container,
  TextField,
  Button,
  Link,
} from "@mui/material";

import api from "../../service/api";

function App() {
  const [email, setEmail] = useState();
  const [error, setError] = useState();

  const navigate = useNavigate();

  const sendLogin = async () => {
    var returnStatus, returnResponse;
    await api
      .post(
        `school/forgotpassword`,
        { email: email, publicUrl: window.location.host },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept",
          },
        }
      )
      .then((res) => {
        console.log(res.data);

        if (res.status == 200) {
          localStorage.setItem("token", res.data.token);
          navigate("/school/login", { replace: true });
        }

        returnStatus = "success";
        returnResponse = "Berhasil";
      })
      .catch((err) => {
        console.log(err);
        returnStatus = "error";
        returnResponse = "Gagal";
        setError(err.toString());
      });

    return {
      status: returnStatus,
      text: returnResponse,
    };
  };

  return (
    <Container maxWidth="lg" sx={{ textAlign: "center" }}>
      <Box>
        <Typography variant="h3" sx={{ marginBottom: "8px" }}>
          Ganti Password Sekolah
        </Typography>
        <Box sx={{ marginBottom: "8px" }}>
          <TextField
            label="Email"
            variant="outlined"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </Box>

        <Box>
          <Typography variant="error" sx={{ marginBottom: "8px" }}>
            {error}
          </Typography>
        </Box>
        <Button
          variant="contained"
          sx={{ marginBottom: "8px" }}
          onClick={() => sendLogin()}
        >
          Minta Link Pergantian Password
        </Button>
        {/* <Box>
          <Typography variant="p">
            Belum terdaftar? <Link href="/school/register">Register</Link>
          </Typography>
        </Box> */}
        <Box>
          <Typography variant="p">
            Kembali ke <Link href="/school/login">login</Link>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}

export default App;
