import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Tabs,
  Tab,
  Box,
  Typography,
  Container,
  TextField,
  Button,
  Link,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";

import AdapterLuxon from "@mui/lab/AdapterLuxon";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";

import api from "../../service/api";

function RegistrationPage() {
  const [dob, setDob] = useState(null);
  const [gender, setGender] = useState("");
  const [name, setName] = useState();
  const [nisn, setNisn] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [passwordConfirmation, setPasswordConfirmation] = useState();
  const [error, setError] = useState();
  const [npsn, setNpsn] = useState();

  const navigate = useNavigate();

  const sendRegister = async () => {
    if (password !== passwordConfirmation) {
      setError("Password tidak sama");
      return;
    }
    var returnStatus, returnResponse;
    await api
      .post(
        `student/register`,
        {
          nisn: nisn,
          npsn: npsn,
          name: name,
          email: email,
          password: password,
          birthDate: dob,
          gender: gender,
          publicUrl: window.location.host,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept",
          },
        }
      )
      .then((res) => {
        console.log(res.data);

        if (res.status == 200) {
          navigate("/student/login", { replace: true });
        }

        returnStatus = "success";
        returnResponse = "Berhasil";
      })
      .catch((err) => {
        console.log(err);
        returnStatus = "error";
        returnResponse = "Gagal";
        setError(err.toString());
      });

    return {
      status: returnStatus,
      text: returnResponse,
    };
  };
  return (
    <Container
      maxWidth="lg"
      sx={{
        textAlign: "center",
        width: "50%",
      }}
    >
      <Box fullWidth>
        <Typography variant="h3" sx={{ marginBottom: "8px" }}>
          Registrasi Murid
        </Typography>
        <Box sx={{ marginBottom: "8px", width: "100%" }}>
          <TextField
            fullWidth
            label="Nama"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </Box>
        <Box sx={{ marginBottom: "8px", width: "100%" }}>
          <TextField
            fullWidth
            label="NPSN"
            variant="outlined"
            value={npsn}
            onChange={(e) => setNpsn(e.target.value)}
            required
          />
        </Box>
        <Box sx={{ marginBottom: "8px", width: "100%" }}>
          <TextField
            fullWidth
            label="NISN"
            variant="outlined"
            value={nisn}
            onChange={(e) => setNisn(e.target.value)}
            required
          />
        </Box>
        <Box sx={{ marginBottom: "8px" }}>
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Box>
        <Box sx={{ marginBottom: "8px" }}>
          <TextField
            fullWidth
            label="Password"
            variant="outlined"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </Box>
        <Box sx={{ marginBottom: "8px" }}>
          <TextField
            fullWidth
            label="Konfirmasi Password"
            variant="outlined"
            type="password"
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            required
          />
        </Box>
        <Box sx={{ marginBottom: "8px" }}>
          <FormControl fullWidth required>
            <InputLabel>Jenis Kelamin</InputLabel>
            <Select
              value={gender}
              label="Jenis Kelamin"
              default
              onChange={(e) => setGender(e.target.value)}
            >
              <MenuItem value={"Laki-laki"}>Laki-laki</MenuItem>
              <MenuItem value={"Perempuan"}>Perempuan</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ marginBottom: "8px" }}>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DatePicker
              label="Tanggal Lahir"
              value={dob}
              onChange={(newValue) => {
                setDob(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  sx={{ textAlign: "left" }}
                  required
                />
              )}
            />
          </LocalizationProvider>
        </Box>

        <Box>
          <Typography variant="error" sx={{ marginBottom: "8px" }}>
            {error}
          </Typography>
        </Box>
        <Button
          variant="contained"
          sx={{ marginBottom: "8px" }}
          onClick={() => sendRegister()}
        >
          Register
        </Button>
        <Box>
          <Typography variant="p">
            Sudah punya akun? <Link href="/student/login">Login.</Link>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}

export default RegistrationPage;
