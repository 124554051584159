import React, { useState, useEffect } from "react";

function useLoginStatus() {
  const [loginStatus, setLoginStatus] = useState(false);
  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) {
      setLoginStatus(false);
    } else {
      setLoginStatus(true);
    }

    console.log("Login Status", loginStatus);
  }, [loginStatus]);

  const logout = () => {
    localStorage.removeItem("token");
    setLoginStatus(false);
  };

  const login = () => {
    setLoginStatus(true);
  };
  return [loginStatus, login, logout];
}

export default useLoginStatus;
