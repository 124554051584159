import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Box,
} from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import api from "../../service/api";
import config from "../../config";

import {css, cx} from "@emotion/css";


const TransactionHistoryPage = () => {
  const [transactionData, setBuyDraftData] = useState();
  const { id: transactionId } = useParams()


  useEffect(async () => {
    await api
      .get(`school/transactions/${transactionId}`, {
        headers: {
          "x-access-token": `${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      })
      .then((res) => {
        console.log(res.data);

        if (res.status == 200) {
          // Success
          setBuyDraftData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <Typography>Riwayat Transaksi</Typography>
      {transactionData && <>
            <Box>
              <Typography variant="h5">Detil Pembelian Buku</Typography>

              <TableContainer container="div">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nomor</TableCell>
                      <TableCell>Buku</TableCell>
                      <TableCell>Harga Per Tahun</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  {transactionData?.books && transactionData.books.map((i, v) => (
                    <TableRow>
                      <TableCell>{v + 1}</TableCell>
                      <TableCell>
                        <img
                          src={`${config.baseUrl}/school/books/${i._id}/thumb`}
                          alt={`${i.title}`}
                          className={css`
                            height: 100px;
                          `}
                        />
                      </TableCell>
                      <TableCell>{i.ISBN}</TableCell>
                      <TableCell>{i.title}</TableCell>
                      <TableCell>
                        Rp {i?.pricePerSemester.toLocaleString()}
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              </TableContainer>
              <Typography variant="h5">Detil Murid</Typography>

              <TableContainer container="div">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nomor</TableCell>
                      <TableCell>NISN</TableCell>
                      <TableCell>Nama Murid</TableCell>
                      <TableCell>Email</TableCell>
                    </TableRow>
                  </TableHead>
                  {transactionData?.students?.map((i, v) => (
                    <TableRow>
                      <TableCell>{v + 1}</TableCell>
                      <TableCell>{i.nisn}</TableCell>
                      <TableCell>{i.name}</TableCell>
                      <TableCell>{i.email}</TableCell>
                    </TableRow>
                  ))}
                </Table>
              </TableContainer>

              <Typography variant="h5">Detil Pembayaran</Typography>

              <TableContainer container="div">
                <Table>
                  {transactionData?.transaction ? (
                    <>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Subtotal Per Murid Per Tahun</TableCell>
                        <TableCell>
                          Rp{" "}
                          {transactionData.transaction.pricePerStudentPerSemester.toLocaleString()}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>
                          Subtotal Per Murid (Jumlah murid:{" x"}
                          {transactionData.students.length})
                        </TableCell>
                        <TableCell>
                          Rp{" "}
                          {transactionData.transaction.pricePerSemester.toLocaleString()}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>
                          Total (Durasi: {transactionData.transaction.duration})
                        </TableCell>
                        <TableCell>
                          Rp {transactionData.transaction.price.toLocaleString()}
                        </TableCell>
                      </TableRow>
                    </>
                  ) : (
                    false
                  )}
                </Table>
              </TableContainer>
              <Box>
                <Box>
                  <Typography>{transactionData.isPaid ? `Telah dibayar tanggal ${format(new Date(transactionData.paidAt), "dd MMM yyyy")}` : `Belum Lunas`}</Typography>
                </Box>
              </Box>
            </Box>{" "}
          </>}
    </>
  );
};

export default TransactionHistoryPage;
