import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  Button,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import { css, cx } from "@emotion/css";

import api from "../../service/api";
import theme from "../../theme";
import { Fade } from "react-reveal";
import config from "../../config";

const Home = () => {
  const [bookData, setBookData] = useState([]);
  const [updateId, setUpdateId] = useState(0);

  const getBookData = async () => {
    let returnStatus = "";
    let returnResponse = "";
    await api
      .get(`/books`, {
        headers: {
          "x-access-token": `${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      })
      .then((res) => {
        console.log(res.data);
        setBookData((d) => res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getBookData();
  }, [updateId]);

  return (
    <>
      <Box>
        <Box
          sx={{
            justifyContent: "center",
            display: "flex",
            background: "#1b1464",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "100%",
              transform: "translate(-70%, -40%)",
              visibility: { xs: "hidden", sm: "visible" },
              width: { xs: "0px", sm: "80vw" },
              "& img": {
                width: "100%",
              },
            }}
          >
            <Fade right>
              <img src="home.png" alt="PCI edukasi" />
            </Fade>
          </Box>
          <Container
            maxWidth="lg"
            sx={{
              minHeight: { xs: "100vh" },
              color: "#ffffff",
              zIndex: 2,
              padding: { xs: "0px 10px", sm: "0px 30px" },
            }}
          >
            <Box
              sx={{
                position: "relative",
                top: { xs: "50%" },
                transform: { xs: "translate(0,-50%)" },
                display: "flex",
                justifyContent: "left",
                flexWrap: { xs: "wrap", sm: "nowrap" },
              }}
            >
              <Box
                sx={{
                  maxWidth: "400px",
                  flex: { xs: "1 1 100%", sm: "0 0 0px" },
                  visibility: { xs: "visible", sm: "hidden" },
                  marginLeft: { xs: "auto", sm: "0" },
                  marginRight: { xs: "auto", sm: "0" },
                }}
              >
                <Fade>
                  <img src="home.png" alt="PCI edukasi" width="100%" />
                </Fade>
              </Box>
              <Fade left>
                <Box
                  sx={{
                    flex: "0 1 512px",
                    textAlign: { xs: "center", sm: "left" },
                    maxWidth: { sm: "50%" },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { md: "36px", xs: "24px" },
                      fontWeight: "900",
                    }}
                  >
                    Sekolah dengan E-Perpustakaan, bukan lagi impian
                  </Typography>
                  <Typography sx={{ fontSize: { md: "32px", xs: "20px" } }}>
                    Telah hadir dengan layanan Pustaka PCI
                  </Typography>
                  <a
                    href="https://wa.me/6281345485100"
                    className={css`
                      text-decoration: none;
                    `}
                  >
                    <Button
                      variant="outlined"
                      sx={{
                        background: "#ffffff",
                        marginTop: "8px",
                        transform: "translateY(0)",
                        transition: "1s",
                        "&:hover": {
                          background: `${theme.palette.secondary.main} !important`,
                          transform: "translateY(-2px)",
                          color: "#ffffff",
                        },
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "1em",
                        marginLeft: { xs: "auto", sm: "0" },
                        marginRight: { xs: "auto", sm: "0" },
                      }}
                    >
                      <i className="fab fa-2x fa-whatsapp"></i>
                      <span>Daftar Sekarang</span>
                    </Button>
                  </a>
                </Box>
              </Fade>
            </Box>
          </Container>
        </Box>
        <Box
          sx={{
            justifyContent: "center",
            display: "flex",
            background: "#ffffff",
          }}
        >
          <Container
            maxWidth="lg"
            sx={{
              minHeight: "100vh",
              color: "#000000",
            }}
          >
            <Box
              sx={{
                position: "relative",
                top: "50%",
                paddingTop: { xs: "1em", sm: "8em" },
                transform: "translate(0,-50%)",
                display: "flex",
                justifyContent: "center",
                flexWrap: { xs: "wrap", sm: "nowrap" },
                alignItems: "flex-end",
                textAlign: "center",
                "& div": {
                  flex: { xs: "1 1 300px", sm: "1 1 0" },
                  padding: "8px",
                },
              }}
            >
              <Box>
                <Fade bottom>
                  <Box>
                    <img
                      alt="Daring"
                      src="landing_undraw_bibliophile_re_xarc.svg"
                      className={css`
                        width: 70%;
                        max-height: 300px;
                      `}
                    />
                  </Box>
                  <Box sx={{ height: "200px" }}>
                    <Typography variant="h4">Hemat</Typography>
                    <Typography variant="p">
                      Pustaka PCI berupa konten digital diakses online. Hemat biaya dan tempat.
                    </Typography>
                  </Box>
                </Fade>
              </Box>
              <Box>
                <Fade bottom delay={200}>
                  <Box>
                    <img
                      alt="Berkualitas"
                      src="landing_undraw_online_articles_re_yrkj.svg"
                      className={css`
                        width: 100%;
                        max-height: 300px;
                      `}
                    />
                  </Box>

                  <Box sx={{ height: "200px" }}>
                    <Typography variant="h4">Berkualitas</Typography>
                    <Typography variant="p">
                      Sarana perpustakaan dengan buku yang berkualitas. Telah di-review oleh pihak resmi.
                    </Typography>
                  </Box>
                </Fade>
              </Box>
              <Box>
                <Fade bottom delay={400}>
                  <Box>
                    <img
                      alt="Mudah"
                      src="landing_undraw_online_re_x00h.svg"
                      className={css`
                        width: 100%;
                        max-height: 300px;
                      `}
                    />
                  </Box>
                  <Box sx={{ height: "200px" }}>
                    <Typography variant="h4">Mudah</Typography>
                    <Typography variant="p">
                      Sangat mudah dan intuitif untuk menggunakan Pustaka PCI.
                      Sama seperti ebook reader.
                    </Typography>
                  </Box>
                </Fade>
              </Box>
            </Box>
          </Container>
        </Box>
        <Box
          sx={{
            justifyContent: "center",
            display: "flex",
            background: "#1b1464",
          }}
        >
          <Container
            maxWidth="lg"
            sx={{
              minHeight: "100vh",
              color: "#ffffff",
            }}
          >
            <Box
              sx={{
                position: "relative",
                top: "50%",
                transform: "translate(0,-50%)",
                justifyContent: "center",
                textAlign: "center",
                paddingTop: "2em",
                paddingBottom: "2em",
              }}
            >
              <Fade bottom delay={200}>
                <Typography variant="h4">
                  Pustaka PCI telah menerbitkan buku-buku berikut!
                </Typography>
                <Box
                  sx={{
                    marginTop: "2em",
                    marginBottom: "2em",
                    display: "flex",
                    gap: "1rem",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  {bookData &&
                    bookData.map((b, i) => (
                      <Box
                        key={i}
                        sx={{
                          backgroundColor: "#fff",
                          boxShadow: "2px 2px 2px 0px #0ff",
                          height: "100px",
                        }}
                      >
                        <img
                          src={`${config.baseUrl}/admin/books/${b._id}/thumb`}
                          alt={`${b.title}`}
                          className={css`
                            height: 100px;
                          `}
                        />
                      </Box>
                    ))}
                </Box>
                <Typography variant="h5">
                  Segera terbit buku berkualitas lainnya!
                </Typography>
              </Fade>
            </Box>
          </Container>
        </Box>
        <Box
         sx={{
          alignItems: "center",
          background: "#fafafa",
        }}
        >
        <Container
          sx={{
            display: { xs: "block", sm: "flex" },
            flexWrap: "wrap",
            alignItems: "center",
            background: "#fafafa",
            padding: "16px",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              maxWidth: { xs: "200px", sm: "250px" },
            }}
          >
            <img className="logo" alt="PCI" src="logo.png" width="100%" />
          </Box>
          <Box
            sx={{
              minWidth: { sm: "200px" },
              marginLeft: { xs: "0", sm: "auto" },
            }}
          >
            <div className="address">
              <Typography
                sx={{
                  fontSize: "0.7rem",
                  textAlign: { xs: "left", sm: "right" },
                }}
              >
                <Box
                  className="icons"
                  sx={{
                    "& :not(:first-child)": {
                      marginLeft: "4px",
                    },
                    "& a": {
                      color: "black",
                    },
                    "& a > i": {
                      color: "black",
                      fontSize: "1.5em",
                    }
                  }}
                >
                  <a
                    href="https://www.instagram.com/pci_edukasi/?hl=en"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a
                    href="https://linktr.ee/pci_edukasi"
                  >
                    <i className="fab fa-whatsapp"></i>
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UC0WnKLY-QHeD6YDNG0BeLHQ"
                  >
                    <i className="fab fa-youtube"></i>
                  </a>
                  {/* <i className="far fa-2x fa-envelope"></i> */}
                </Box>
                PT. Payung Cerdas Indonesia
                <br />
                Bukit Nanggerang Village, Blok F No. 2
                <br />
                RT.02/RW.04, Nanggerang, Tajurhalang, Bogor 16320
                <br />
                Senin - Jumat 08.00 - 17.00 WIB
                <br />
                0813 4548 5100
              </Typography>
            </div>
          </Box>
        </Container>
        </Box>
      </Box>
    </>
  );
};

export default Home;
