import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import Truncate from "react-truncate";

import { css, cx } from "@emotion/css";

import api from "../../service/api";
import config from "../../config";

const BookUpload = () => {
  const navigate = useNavigate();
  const [bookData, setBookData] = useState([]);
  const [updateId, setUpdateId] = useState(0);

  const [alert, setAlert] = useState({ type: "", text: "", isOpen: false });

  const getBookData = async () => {
    let returnStatus = "";
    let returnResponse = "";
    await api
      .get(`school/books`, {
        headers: {
          "x-access-token": `${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      })
      .then((res) => {
        console.log(res.data);
        setBookData((d) => res.data);
        returnStatus = "success";
        returnResponse = "Book Upload Berhasil";
      })
      .catch((err) => {
        console.log(err);
        returnStatus = "error";
        returnResponse = "Upload Gagal";
      });

    return {
      status: returnStatus,
      text: returnResponse,
    };
  };

  useEffect(() => {
    getBookData();
  }, [updateId]);

  const handleChange = (prop) => (e) => {
    setBookData({ ...bookData, [prop]: e.target.value });
  };

  const keyMapping = [
    { t: "ISBN", v: "ISBN" },
    { t: "Judul", v: "title" },
    { t: "Penulis/Penerbit", v: "author" },
    { t: "Publisher", v: "publisher" },
    { t: "Tahun terbit", v: "publishYear" },
    { t: "Jenjang", v: "schoolLevel" },
    { t: "Deskripsi", v: "description" },
    { t: "Harga (/Tahun)", v: "pricePerSemester" },
  ];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          gap: "12px",
          p: "12px",
          m: 0,
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          sx={{ flex: "0 0 100%" }}
        >
          Katalog Buku
        </Typography>

        <TableContainer container="div">
          <Table sx={{ tableLayout: "fixed" }}>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    width: "10em",
                  }}
                >
                  Gambar
                </TableCell>
                {keyMapping.map((k) => (
                  <TableCell
                    sx={{
                      width: k.t === "ISBN" ? "15em" : "10em",
                    }}
                    key={k.t}
                  >
                    {k.t}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {bookData.map((i) => (
              <TableRow>
                <TableCell
                  className={css`
                    width: 100px;
                  `}
                >
                  <img
                    src={`${config.baseUrl}/admin/books/${i._id}/thumb`}
                    alt={`${i.title}`}
                    className={css`
                      height: 100px;
                    `}
                  />
                </TableCell>

                {keyMapping.map((k) => (
                    <TableCell
                      className={
                        k.t === "ISBN"
                          ? css`
                              white-space: nowrap;
                            `
                          : ""
                      }
                    >
                      {k.t === "Deskripsi" ? (
                        <Truncate
                          lines={2}
                          width={k.t === "ISBN" ? 200 : 100}
                          trimWhiteSpace
                        >
                          {k.f ? k.f(i[k.v]) : i[k.v]}
                        </Truncate>
                      ) : k.f ? (
                        k.f(i[k.v])
                      ) : (
                        i[k.v]
                      )}
                    </TableCell>
                  ))}
                {/* {keyMapping.map((k) => (
                  <TableCell>
                    <Truncate
                      lines={3}
                      width={k.t === "ISBN" ? 200 : 100}
                      trimWhiteSpace
                    >
                      {i[k.v]}
                    </Truncate>
                  </TableCell>
                ))} */}
                {/* <TableCell>
                  <span
                    className={css`
                      display: flex;
                      gap: 4px;
                    `}
                  >
                    <Button
                      variant="outlined"
                      startIcon={<EditIcon />}
                      onClick={() => {
                        navigate(`/admin/books/${i._id}`);
                      }}
                    >
                      Edit
                    </Button>
                  </span>
                </TableCell> */}
                {/* <TableCell><p>{JSON.stringify(i)}</p></TableCell> */}
              </TableRow>
            ))}
          </Table>
        </TableContainer>
      </Box>
      <Snackbar
        open={alert?.isOpen}
        autoHideDuration={1000}
        onClose={() => setAlert((a) => ({ ...a, isOpen: false }))}
        message={alert.text}
      ></Snackbar>
    </>
  );
};

export default BookUpload;
