import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Tabs,
  Tab,
  Box,
  Typography,
  Container,
  TextField,
  Button,
  Link,
} from "@mui/material";

import api from "../../service/api";
import useAuth from "../../service/auth";

import { useSnackbar } from "notistack";

function App({ loginHook }) {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();
  const { login } = useAuth();

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const sendLogin = async (e) => {
    e.preventDefault();
    var returnStatus, returnResponse;
    await api
      .post(
        `school/login`,
        { email: email, password: password },
        {
          headers: {},
        }
      )
      .then((res) => {
        console.log(res.data);

        if (res.status === 200) {
          localStorage.setItem("token", res.data.token);
          login("school");
          loginHook();
          navigate("/school/books", { replace: true });
        }

        enqueueSnackbar("Login Sukses", { variant: "success"} );

        returnStatus = "success";
        returnResponse = "Berhasil";
      })
      .catch((err) => {
        console.log(err);
        returnStatus = "error";
        returnResponse = "Gagal";

        enqueueSnackbar("Login Gagal", { variant: "error"} );

        if (err.response.status === 401) {
          setError("Email belum terverifikasi");
        } else if (err.response.status === 404) {
          setError("Email atau password salah");
        }
      });

    return {
      status: returnStatus,
      text: returnResponse,
    };
  };

  return (
    <Container maxWidth="lg" sx={{ textAlign: "center" }}>
      <Box>
        <form onSubmit={(e) => sendLogin(e)}>
          <Typography variant="h3" sx={{ marginBottom: "8px" }}>
            Login Sekolah
          </Typography>
          <Box sx={{ marginBottom: "8px" }}>
            <TextField
              label="Email"
              variant="outlined"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Box>
          <Box sx={{ marginBottom: "8px" }}>
            <TextField
              label="Password"
              variant="outlined"
              type="password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </Box>
          {/* <Box>
            <Typography variant="error" sx={{ marginBottom: "8px" }}>
              {error}
            </Typography>
          </Box> */}
          <Button
            type="submit"
            variant="contained"
            sx={{ marginBottom: "8px" }}
          >
            Login
          </Button>
        </form>
        {/* <Box>
          <Typography variant="p">
            Lupa password? <Link href="#">Kirim email konfirmasi.</Link>
          </Typography>
        </Box> */}
        <Box>
          <Typography variant="p">
            {/* Belum terdaftar? <Link href="/school/register">Register.</Link> */}
            Lupa password?{" "}
            <Link href="/school/forgotpassword">Klik disini.</Link>
          </Typography>
        </Box>
        <Box>
          <Typography variant="p">
            Belum terdaftar?{" "}
            <Link href="https://wa.me/6281345485100">Kontak admin PCI</Link>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}

export default App;
