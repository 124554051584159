import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {
  Box,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useEffect, useState, useRef } from "react";
import {
  Link,
  matchPath,
  NavLink,
  Outlet,
  useLocation,
} from "react-router-dom";
import api from "../../service/api";
import theme from "../../theme";

import { Menu, Button } from "@mui/material";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SchoolIcon from "@mui/icons-material/School";
import PersonIcon from "@mui/icons-material/Person";
import HistoryIcon from "@mui/icons-material/History";

function App() {
  const location = useLocation();
  const [profile, setProfile] = useState(false);
  const getProfile = async () => {
    await api
      .get(`school/profile`, {
        headers: {
          "x-access-token": `${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      })
      .then((res) => {
        console.log(res.data);
        setProfile((d) => res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getProfile();
  }, []);

  const navListRef = useRef();

  const navs = [
    { to: "books", icon: <MenuBookIcon />, text: "Koleksi Buku" },
    {
      to: "students",
      icon: <SchoolIcon />,
      text: "Daftar Murid",
    },
    {
      to: "buybooks",
      icon: <AccountBalanceIcon />,
      text: "Beli Buku",
    },
    {
      to: "profile",
      icon: <PersonIcon />,
      text: "Profil Sekolah",
    },
    {
      to: "transactions",
      icon: <HistoryIcon />,
      text: "Riwayat Transaksi",
    },
  ];

  const navList = (ref) => (
    <List
      ref={ref}
      sx={{
        position: "sticky",
        "& a": {
          color: "black",
        },
      }}
    >
      {navs.map((n) => (
        <ListItem to={n.to} component={NavLink} end>
          <ListItemIcon>{n.icon}</ListItemIcon>
          <ListItemText primary={n.text} />
        </ListItem>
      ))}
    </List>
  );

  const [xsMenu, setXsMenu] = useState({ open: false, anchorEl: null });

  const baseUrl = "/school/";
  const currentLocation = navs.find(
    (n) => location.pathname === baseUrl + n.to
  );

  return (
    <Box>
      <Box
        sx={{
          visibility: { xs: "visible", sm: "hidden" },
          height: { xs: "auto", sm: "0px" },
          textAlign: "center",
        }}
      >
        <Button
          aria-expanded={xsMenu.open || undefined}
          onClick={(e) =>
            setXsMenu((a) => ({
              open: !a.open,
              anchorEl: !a.open ? e.target : null,
            }))
          }
          sx={{
            display: "flex",
            gap: "0.5em",
            alignItems: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          {currentLocation?.icon}
          {currentLocation?.text || "Navigasi"}
          <ArrowDropDownIcon />
        </Button>
        <Menu
          open={xsMenu.open}
          anchorEl={xsMenu.anchorEl}
          onClose={() =>
            setXsMenu((a) => ({
              open: false,
              anchorEl: null,
            }))
          }
          onClick={() =>
            setXsMenu((a) => ({
              open: false,
              anchorEl: null,
            }))
          }
        >
          {navList(navListRef)}
        </Menu>
      </Box>
      <Box
        sx={{
          width: "240px",
          bgcolor: `${theme.palette.background.paper}`,
          position: "fixed",
          height: "100%",
          visibility: { xs: "hidden", sm: "visible" },
        }}
      >
        <nav>{navList(navListRef)}</nav>
      </Box>
      <Box sx={{ marginLeft: { xs: "0", sm: "240px" } }}>
        <Box sx={{ padding: "0.2em" }}>
          <Box
            sx={{
              margin: "auto",
              width: "calc(100% - 2em)",
              borderBottom: "solid 1px #dddddd",
            }}
          >
            <Typography>
              Dasbor Sekolah | {profile?.nama} [{profile?.npsn}]
            </Typography>
          </Box>
        </Box>
        <Container maxWidth="lg">
          <Outlet />
        </Container>
      </Box>
    </Box>
  );
}

export default App;
