import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Button,
  Box
} from "@mui/material";
import { format } from "date-fns";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import api from "../../service/api";

const TransactionHistoryPage = () => {
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState();

  useEffect(async () => {
    await api
      .get(`school/transactions`, {
        headers: {
          "x-access-token": `${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      })
      .then((res) => {
        console.log(res.data);

        if (res.status == 200) {
          // Success
          setTransactions(res.data.transactions);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <Box sx={{p: "12px", m: 0}}>
      <Typography variant="h5">Riwayat Transaksi</Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Waktu Transaksi</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Harga</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          {transactions && transactions.sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt)).map((t) => (
            <TableRow>
              <TableCell>
                {t.createdAt &&
                  format(new Date(t.createdAt), "dd MMM yyyy HH:mm:ss")}
              </TableCell>
              <TableCell>{t.isPaid ? "LUNAS" : "PENDING"}</TableCell>
              <TableCell>{`Rp ${t.price.toLocaleString()}`}</TableCell>
              <TableCell><Button onClick={() => navigate(t._id)}>Detail</Button></TableCell>
            </TableRow>
          ))}
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TransactionHistoryPage;
