import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import { css, cx } from "@emotion/css";

import BookReader from "../../components/BookReader.jsx";
import api from "../../service/api";
import config from "../../config";
import Truncate from "react-truncate";

import ImportContactsIcon from "@mui/icons-material/ImportContacts";

const BookUpload = () => {
  const navigate = useNavigate();
  const [bookData, setBookData] = useState([]);
  const [pendingBookData, setPendingBookData] = useState([]);
  const [updateId, setUpdateId] = useState(0);

  const [alert, setAlert] = useState({ type: "", text: "", isOpen: false });

  const getBookData = async () => {
    let returnStatus = "";
    let returnResponse = "";
    await api
      .get(`student/books/free`, {
        headers: {
          "x-access-token": `${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      })
      .then((res) => {
        console.log(res.data);
        setBookData((d) => res.data);
        returnStatus = "success";
        returnResponse = "Book Upload Berhasil";
      })
      .catch((err) => {
        console.log(err);
        returnStatus = "error";
        returnResponse = "Upload Gagal";
      });

    return {
      status: returnStatus,
      text: returnResponse,
    };
  };

  useEffect(() => {
    getBookData();
  }, [updateId]);

  const handleChange = (prop) => (e) => {
    setBookData({ ...bookData, [prop]: e.target.value });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          gap: "12px",
          p: "12px",
          m: 0,
        }}
      >
        <Typography
          variant="h6"
          gutterBottom
          component="div"
          sx={{ flex: "0 0 100%" }}
        >
          Buku Langganan Saya
        </Typography>

        <TableContainer container="div">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Sampul</TableCell>
                <TableCell>Judul</TableCell>
                <TableCell>Penulis/Penerbit</TableCell>
                <TableCell>Info lain</TableCell>
                <TableCell>Akhir masa berlangganan</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            {bookData?.map((i) => {
              return (
                <TableRow id={i._id}>
                  <TableCell
                    className={css`
                      width: 100px;
                    `}
                  >
                    <img
                      src={`${config.baseUrl}/school/books/${i._id}/thumb`}
                      alt={`${i.title}`}
                      className={css`
                        height: 100px;
                      `}
                    />
                  </TableCell>

                  <TableCell>{i.title}</TableCell>
                  <TableCell>
                    {i.author}
                    <br />
                    {i.publisher}
                    <br />
                    {i.publishYear}
                  </TableCell>
                  <TableCell>
                    ISBN:{i.ISBN}
                    <br />
                    Jenjang:{i.schoolLevel}
                    <br />
                    <Truncate>{i.description}</Truncate>
                  </TableCell>
                  <TableCell>
                    <span
                      className={css`
                        display: flex;
                        gap: 4px;
                      `}
                    >
                      <Button
                        variant="outlined"
                        startIcon={<ImportContactsIcon />}
                        onClick={() => {
                          localStorage.setItem("bookid", i._id);
                          navigate("/student/read");
                        }}
                      >
                        Baca
                      </Button>
                    </span>
                  </TableCell>
                </TableRow>
              );
            })}
          </Table>
        </TableContainer>
      </Box>

      <Snackbar
        open={alert?.isOpen}
        autoHideDuration={1000}
        onClose={() => setAlert((a) => ({ ...a, isOpen: false }))}
        message={alert.text}
      ></Snackbar>
    </>
  );
};

export default BookUpload;
