import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Tabs,
  Tab,
  Box,
  Typography,
  Container,
  TextField,
  Button,
  Link,
} from "@mui/material";

import api from "../../service/api";
import useAuth from "../../service/auth";

function App({ loginHook }) {
  const [npsn, setNpsn] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();

  const navigate = useNavigate();

  const { login } = useAuth();

  const sendLogin = async () => {
    var returnStatus, returnResponse;
    await api
      .post(`student/login`, { email: email, password: password, npsn: npsn })
      .then((res) => {
        console.log(res.data);

        if (res.status === 200) {
          localStorage.setItem("token", res.data.token);
          loginHook();
          login("student").then(() => {
            navigate("/student/mybooks", { replace: true });
          });
        }
        returnStatus = "success";
        returnResponse = "Berhasil";
      })
      .catch((err) => {
        console.log(err);
        returnStatus = "error";
        returnResponse = "Gagal";
        setError(err.toString());
      });

    return {
      status: returnStatus,
      text: returnResponse,
    };
  };

  return (
    <Container maxWidth="lg" sx={{ textAlign: "center" }}>
      <Box>
        <Typography variant="h3" sx={{ marginBottom: "8px" }}>
          Login Murid
        </Typography>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            sendLogin();
          }}
        >
          <Box sx={{ marginBottom: "8px" }}>
            <TextField
              label="NPSN Sekolah"
              variant="outlined"
              value={npsn}
              onChange={(e) => {
                setNpsn(e.target.value);
              }}
            />
          </Box>
          <Box sx={{ marginBottom: "8px" }}>
            <TextField
              label="Email"
              variant="outlined"
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Box>
          <Box sx={{ marginBottom: "8px" }}>
            <TextField
              label="Password"
              variant="outlined"
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </Box>
          <Box>
            <Typography variant="error" sx={{ marginBottom: "8px" }}>
              {error}
            </Typography>
          </Box>
          <Button
            color="primary"
            variant="contained"
            sx={{ marginBottom: "8px" }}
            type="submit"
          >
            Login
          </Button>
        </form>
        <Box>
          <Typography variant="p">
            Lupa password? <Link href="/student/forgotpassword">Reset Password</Link>
          </Typography>
        </Box>
        <Box>
          <Typography variant="p">
            Belum terdaftar? <Link href="/student/register">Register</Link>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}

export default App;
