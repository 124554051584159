import {createStore} from "redux";

// LOADER REDUX STORE
// code referenced from https://nahmedalizai.medium.com/simple-react-overlay-spinner-and-spinner-with-react-redux-4006897a4d1e

// action
const LOADER = 'LOADER'

// action
export const setLoader = (loading) => {
  return {
    type: LOADER,
    payload: loading
  }
}

// reducer
const initialState = {
  isLoading : false
}

const loadingActionReducer = (state = initialState, action) => {
  switch(action.type) {
  case LOADER: return {
    ...state,
    isLoading: action.payload
  }
  default: return state
  }
}

// Store
export const store = createStore(loadingActionReducer)


// NOTIFICATION REDUX STORE

// action
const NOTIF = 'NOTIF'

// action
export const setNotif = (notif) => {
  return {
    type: NOTIF,
    payload: notif
  }
}

// reducer
const initialStateNotif = {
  notif: null
}

const notifActionReducer = (state = initialState, action) => {
  switch(action.type) {
  case NOTIF: return {
    ...state,
    notif: action.payload
  }
  default: return state
  }
}

// Store
export const notifStore = createStore(loadingActionReducer)