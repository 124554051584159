import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Tabs,
  Tab,
  Box,
  Typography,
  Container,
  TextField,
  Button,
  Link,
} from "@mui/material";

import api from "../../service/api";

function ProfilePage() {
  const [profile, setProfile] = useState({
    nama: "",
    npsn: "",
    email: "",
    alamat: "",
    status: "",
    jenjang: "",
    desaKelurahan: "",
    kecamatan: "",
    kabupatenKota: "",
    provinsi: "",
    postcode: "",
    phoneNumber: "",
    yearEst: "",
    principalsName: "",
    adminsName: "",
    adminsWhatsapp: "",
  });
  const [error, setError] = useState();

  const navigate = useNavigate();

  const sendProfile = async () => {
    var returnStatus, returnResponse;
    await api
      .put(`school/profile`, profile, {
        headers: {
          "x-access-token": `${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      })
      .then((res) => {
        console.log(res.data);

        if (res.status == 200) {
          // Success
        }

        returnStatus = "success";
        returnResponse = "Berhasil";
      })
      .catch((err) => {
        console.log(err);
        returnStatus = "error";
        returnResponse = "Gagal";
        setError(err.toString());
      });

    return {
      status: returnStatus,
      text: returnResponse,
    };
  };

  const getProfile = async () => {
    var returnStatus, returnResponse;
    await api
      .get(`school/profile`, {
        headers: {
          "x-access-token": `${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      })
      .then((res) => {
        console.log(res.data);

        if (res.status == 200) {
          setProfile(res.data);
        }

        returnStatus = "success";
        returnResponse = "Berhasil";
      })
      .catch((err) => {
        console.log(err);
        returnStatus = "error";
        returnResponse = "Gagal";
        setError(err.toString());
      });

    return {
      status: returnStatus,
      text: returnResponse,
    };
  };

  const handleChange = (prop) => (e) => {
    setProfile({ ...profile, [prop]: e.target.value });
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <Container maxWidth="lg" sx={{ textAlign: "left" }}>
      <Box sx={{ width: { xs: "100%", lg: "50%" }, p: "12px" }}>
        <Box>
          <Typography variant="h5" sx={{ marginBottom: "8px" }}>
            Profil Sekolah
          </Typography>
          <Box sx={{ marginBottom: "8px" }}>
            <TextField
              fullWidth
              label="NPSN"
              variant="outlined"
              value={profile?.npsn}
              onChange={handleChange("npsn")}
            />
          </Box>
          <Box sx={{ marginBottom: "8px" }}>
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              value={profile?.email}
              onChange={handleChange("email")}
            />
          </Box>
          <Box sx={{ marginBottom: "8px" }}>
            <TextField
              fullWidth
              label="Nama"
              variant="outlined"
              value={profile?.nama}
              onChange={handleChange("nama")}
            />
          </Box>
          <Typography variant="h5" sx={{ marginBottom: "8px" }}>
            Detil Sekolah
          </Typography>
          <Box sx={{ marginBottom: "8px" }}>
            <TextField
              fullWidth
              label="Alamat"
              variant="outlined"
              value={profile?.alamat}
              onChange={handleChange("alamat")}
            />
          </Box>
          <Box sx={{ marginBottom: "8px" }}>
            <TextField
              fullWidth
              label="Status"
              variant="outlined"
              value={profile?.status}
              onChange={handleChange("status")}
            />
          </Box>
          <Box sx={{ marginBottom: "8px" }}>
            <TextField
              fullWidth
              label="Jenjang"
              variant="outlined"
              value={profile?.jenjang}
              onChange={handleChange("desaKelurahan")}
            />
          </Box>
          <Typography variant="h5" sx={{ marginBottom: "8px" }}>
            Lokasi Sekolah
          </Typography>
          <Box sx={{ marginBottom: "8px" }}>
            <TextField
              fullWidth
              label="Kecamatan"
              variant="outlined"
              value={profile?.kecamatan}
              onChange={handleChange("kecamatan")}
            />
          </Box>
          <Box sx={{ marginBottom: "8px" }}>
            <TextField
              fullWidth
              label="Desa Kelurahan"
              variant="outlined"
              value={profile?.desaKelurahan}
              onChange={handleChange("desaKelurahan")}
            />
          </Box>
          <Box sx={{ marginBottom: "8px" }}>
            <TextField
              fullWidth
              label="Kabupaten/Kota"
              variant="outlined"
              value={profile?.kabupatenKota}
              onChange={handleChange("kabupatenKota")}
            />
          </Box>
          <Box sx={{ marginBottom: "8px" }}>
            <TextField
              fullWidth
              label="Provinsi"
              variant="outlined"
              value={profile?.provinsi}
              onChange={handleChange("provinsi")}
            />
          </Box>
          <Box sx={{ marginBottom: "8px" }}>
            <TextField
              fullWidth
              label="Kode Pos"
              variant="outlined"
              value={profile?.postCode}
              onChange={handleChange("postcode")}
            />
          </Box>
          <Typography variant="h5" sx={{ marginBottom: "8px" }}>
            Kontak Sekolah
          </Typography>
          <Box sx={{ marginBottom: "8px" }}>
            <TextField
              fullWidth
              label="Nomor Telepon"
              variant="outlined"
              value={profile?.phoneNumber}
              onChange={handleChange("phoneNumber")}
            />
          </Box>
          <Box sx={{ marginBottom: "8px" }}>
            <TextField
              fullWidth
              label="Nama Admin"
              variant="outlined"
              value={profile?.adminsName}
              onChange={handleChange("adminsName")}
            />
          </Box>
          <Box sx={{ marginBottom: "8px" }}>
            <TextField
              fullWidth
              label="Nomor Whatsapp Admin"
              variant="outlined"
              value={profile?.adminsWhatsapp}
              onChange={handleChange("adminsWhatsapp")}
            />
          </Box>
          <Box>
            <Typography variant="error" sx={{ marginBottom: "8px" }}>
              {error}
            </Typography>
          </Box>
          <Button
            variant="contained"
            sx={{ marginBottom: "8px" }}
            onClick={() => sendProfile()}
          >
            Save
          </Button>
          <Box>
            <Typography variant="p">
              Lupa password? <Link href="#">Kirim email konfirmasi.</Link>
            </Typography>
          </Box>
          <Box>
            <Typography variant="p">
              Belum terdaftar? <Link href="#">Register.</Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default ProfilePage;
